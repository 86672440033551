import {
  Container,
  Dividers,
  ContainerBody,
  PalomaVideo,
  ContainerExperience,
} from "./styles"
import { Description } from "./Description"
import { Steps } from "./Steps"
import { Footer } from "./Footer"
import RecorridoRapidoSection from "./RecorridoRapidoSection"
import Paloma from "../../../assets/gifs/Paloma.webm"

export const Body = () => {
  return (
    <Container>
      <ContainerBody>
        <ContainerExperience id="containerExperiencia">
          <Description />
          <Steps />
        </ContainerExperience>
        {/* <Dividers flexItem /> */}
        <RecorridoRapidoSection />
        <PalomaVideo src={Paloma} loop muted />
        <Dividers flexItem />
      </ContainerBody>
      <Footer />
    </Container>
  )
}
