import { Box, Divider } from "@mui/material"
import { styled } from "@mui/material/styles"

export const Container = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
}))

export const ContainerBody = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    width: "100%",
    position: "relative",
    paddingBottom: "30px",
    "&::before": {
      content: '""',
      position: "absolute",
      left: "0",
      bottom: "0",
      height: "1px",
      margin: "auto",
      width: "100%",
      borderBottom: "3px solid #1A72B8",
    },
  },
}))

export const ContainerExperience = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    width: "100%",
    // position: "relative",
    // paddingBottom: "30px",
  },
}))

export const Dividers = styled(Divider)(({ theme }) => ({
  width: "90%",
  margin: "20px auto 0px auto",
  height: "3px",
  backgroundColor: "#1A72B8",
  borderRadius: "10px",
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}))

export const PalomaVideo = styled("video")(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("md")]: {
    width: "120px",
    height: "120px",
    position: "absolute",
    bottom: -10,
    left: "40%",
    display: "block",
  },
}))
