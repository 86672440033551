import { useEffect } from "react"
import { useAppDispatch } from "../../redux/hooks"
import { SubHeader } from "./SubHeader"
import { Carrousel } from "./Carrousel"
import { Cards } from "./Cards"
import { Body } from "./Body"
import { Buttons } from "./Buttons"

import { useMediaQuery } from "@mui/material"

import { ContainerHome, EmptyContainer } from "./styles"
import { setEdit } from "../../redux/reservas/editReservaSlice"

export const Home = () => {
  const dispatch = useAppDispatch()
  const isMobile = useMediaQuery("(max-width:900px)")

  useEffect(() => {
    dispatch(setEdit())
  }, [])

  return (
    <>
      <ContainerHome>
        <SubHeader />
        {isMobile ? <Carrousel /> : <Cards />}
        <Buttons />
        {/* <VideoWelcome /> */}
        <Body />
      </ContainerHome>
      <EmptyContainer></EmptyContainer>
    </>
  )
}
