import { useNavigate } from "react-router-dom"
import { ButtonAction, Section } from "./styles"

const RecorridoRapidoSection = () => {
  const navigate = useNavigate()
  return (
    <Section id="containerRecorridoRapido">
      <ButtonAction onClick={() => navigate("/recorridorapido")}>
        Ingresá al recorrido rápido
      </ButtonAction>
    </Section>
  )
}

export default RecorridoRapidoSection
