import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"

export const Container = styled(Box)(({ theme }) => ({
  width: "80%",
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  margin: "2rem auto 0px auto",

  [theme.breakpoints.up(1200)]: {
    flexDirection: "column",
    marginTop: "20px",
    width: "80%",
  },
}))

export const Content = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "15px 0px",
  marginBottom: "50px",
  paddingLeft: "20px",
  paddingRight: "20px",
  gap: "12px",

  ".title": {
    width: "100%",
    height: "auto",
    maxWidth: "300px",
  },
}))

export const MainText = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "650px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  fontFamily: "Raleway",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "20px",
  color: "#000000",

  p: {
    margin: "0.7rem 0px",
  },

  span: {
    display: "block",
    fontSize: "14px",
    opacity: "0.8",
  },

  [theme.breakpoints.up(1200)]: {
    fontSize: "18px",
  },
}))

export const MainImage = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "600px",
  display: "flex",
  justifyContent: "center",
  margin: "0 auto",
  alignItems: "center",
  position: "relative",
  marginTop: "-20px",
  marginBottom: "-20px",
  cursor: "pointer",
  minHeight: "300px",

  img: {
    width: "100%",
    height: "auto",
  },

  [theme.breakpoints.up(1200)]: {
    marginTop: "-50px",
    marginBottom: "-50px",
  },
}))
