import PreRecorridoRapido from "../components/PreRecorridoRapido"
import { HeaderLayout } from "../layouts/HeaderLayout"
import { Container } from "./styles/enterTourPageStyles"

import ReactGA from "react-ga4"

export const PreRecorridoRapidoPage = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname + window.location.search,
    title: "Pre Recorrido",
  })

  return (
    <Container>
      <HeaderLayout>
        <PreRecorridoRapido />
      </HeaderLayout>
    </Container>
  )
}
