import { Box, Button } from "@mui/material"
import { styled } from "@mui/material/styles"

export const Section = styled(Box)(({ theme }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "250px",

  [theme.breakpoints.down("md")]: {
    minHeight: "150px",
  },
}))

export const ButtonAction = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  fontWeight: "bold",
  borderRadius: "86px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "15px 20px",
  fontFamily: "Raleway",
  fontStyle: "normal",
  maxWidth: "200px",
  lineHeight: "19px",
  textWrap: "wrap",
  // fontWeight: 700,
  fontSize: "15px",
  color: theme.palette.secondary.contrastText,

  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
    filter: "brightness(0.9)",
  },
}))
